import { createContext, useContext, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLocalStorage } from './useLocalStorage'

const AuthContext = createContext<any>(null)
export const AuthProvider = ({ children }: { children: any }) => {
  const [user, setUser] = useLocalStorage('adminuser', null)
  const navigate = useNavigate()

  const login = async (data: any) => {
    setUser(data)
    navigate('/adsmanagement')
  }

  const logout = () => {
    setUser(null)
    navigate('/login', { replace: true })
  }

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
    }),
    [user],
  )
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return useContext(AuthContext)
}
